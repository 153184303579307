import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Logo from 'components/Logo/Logo';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import Transition from 'components/Transition/Transition';

export default function Splash() {
  const data = useStaticQuery(graphql`
    query {
      SplashImage: file(
        relativePath: { eq: "rui-new-york.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(
    false,
  );
  const [
    // eslint-disable-next-line no-unused-vars
    hideSidebarMenu,
    setHideSidebarMenu,
  ] = React.useState(true);

  const openSidebar = () => {
    setIsSidebarOpen(true);
    setHideSidebarMenu(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const hideSidebar = () => {
    setHideSidebarMenu(true);
  };

  const { pathname } = useLocation();

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-0">
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width="640"
          height="784"
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x="118"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            y="72"
            width="640"
            height="640"
            className="text-gray-50"
            fill="currentColor"
          />
          <rect
            x="118"
            width="404"
            height="784"
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>
      {/* START */}
      <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
        <nav className="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/" aria-label="Home">
                {/* <img
                  className="h-8 w-auto sm:h-10"
                  src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                  alt="Logo"
                /> */}
                <Logo />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  id="main-menu"
                  aria-label="Main menu"
                  aria-haspopup="true"
                  onClick={openSidebar}
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="hidden md:block md:ml-10">
              {pathname === '/projects' ? (
                <a
                  href="#top"
                  className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Projects
                </a>
              ) : (
                <Link
                  to="/projects"
                  className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Projects
                </Link>
              )}
              {/* {pathname === '/publications' ? (
                <a
                  href="#top"
                  className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Publications
                </a>
              ) : (
                <Link
                  to="/publications"
                  className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Publications
                </Link>
              )} */}
              <a
                // href="/rui-zhang-curriculum-vitae.pdf"
                href="https://drive.google.com/file/d/100LCURsJEldJ8Mez2KWDtDas6i6TwAeR/view?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
                className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Resume
                {/* Curriculum vitae */}
              </a>
              {pathname === '/' ? (
                <a
                  href="/contact"
                  className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Contact
                </a>
              ) : (
                <Link
                  to="/contact"
                  className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Contact
                </Link>
              )}
            </div>
          </div>
          {/* <div className="hidden md:block text-right">
            <span className="inline-flex rounded-md shadow-md">
              <span className="inline-flex rounded-md shadow-xs">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:bg-gray-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out"
                >
                  Log in
                </a>
              </span>
            </span>
          </div> */}
        </nav>

        {/* <!--
          Mobile menu, show/hide based on menu open state.

          Entering: "duration-150 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        --> */}
        <Transition
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          show={isSidebarOpen}
          onExited={hideSidebar}
        >
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md">
              <div
                className="rounded-lg bg-white shadow-xs overflow-hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    {/* <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                    alt=""
                  /> */}
                    <Logo />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      aria-label="Close menu"
                      onClick={closeSidebar}
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {pathname === '/projects' ? (
                    <a
                      href="#top"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Projects
                    </a>
                  ) : (
                    <Link
                      to="/"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Projects
                    </Link>
                  )}
                  {/* {pathname === '/publications' ? (
                    <a
                      href="#top"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Publications
                    </a>
                  ) : (
                    <Link
                      to="/publications"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Publications
                    </Link>
                  )} */}
                  <a
                    // href="/rui-zhang-curriculum-vitae.pdf"
                    href="https://drive.google.com/file/d/100LCURsJEldJ8Mez2KWDtDas6i6TwAeR/view?usp=sharing"
                    target="_blank"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    role="menuitem"
                    rel="noopener noreferrer"
                  >
                    Resume
                    {/* Curriculum vitae */}
                  </a>
                  {pathname === '/contact' ? (
                    <a
                      href="#top"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Contact
                    </a>
                  ) : (
                    <Link
                      to="/contact"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Contact
                    </Link>
                  )}
                </div>
                {/* <div>
                <a
                  href="/"
                  className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-gray-100 hover:text-green-700 focus:outline-none focus:bg-gray-100 focus:text-green-700 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Log in
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </Transition>
        {/* END */}
        <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Human-centered Computing, PhD
              </div>
              <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                Hi, I’m Rui!
                {/* hidden md:inline */}
                <br className="" />
                <span className="text-green-600">
                 User Researcher
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                I am a PhD Candidate in{' '}
                <a
                  href="http://www.clemson.edu/cecas/departments/computing/academics/graduates/programsofstudy/phdinhcc.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Human-Centered Computing
                </a>{' '}
                at Clemson University. My research interests
                lie in the field of HCI and
                computer-supported collaboration with a
                focus on human-AI teaming.
                
                Advised by{' '}
                <a
                  href="https://nathanmcneese.weebly.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Dr. Nathan McNeese
                </a>
                , I study various facets of communication strategies in human-AI teams in my dissertation.
                {/* , such as communication directionality between humans and AI, and  */}
                {/* AI's communication proactivity. */}
              </p>
              {/* <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Previously, I've worked on understanding the
                role of social media in breakups and
                identified two social dilemmas by applying
                qualitative methods.
              </p> */}
              {/* <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                <p className="text-base font-medium text-gray-900">
                  Sign up to get notified when it’s ready.
                </p>
                <form
                  action="#"
                  method="POST"
                  className="mt-3 sm:flex"
                >
                  <input
                    aria-label="Email"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out sm:flex-1"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                  >
                    Notify me
                  </button>
                </form>
                <p className="mt-3 text-sm leading-5 text-gray-500">
                  We care about the protection of your data.
                  Read our
                  <a
                    href="/"
                    className="font-medium text-gray-900 underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div> */}
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width="640"
                height="784"
                fill="none"
                viewBox="0 0 640 784"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x="118"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y="72"
                  width="640"
                  height="640"
                  className="text-gray-50"
                  fill="currentColor"
                />
                <rect
                  x="118"
                  width="404"
                  height="784"
                  fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <div className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                  <Img
                    fluid={
                      data.SplashImage.childImageSharp.fluid
                    }
                    className="w-full"
                    alt="Rui Zhang"
                  />
                  {/* <img
                    className="w-full"
                    src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                    alt="Woman making a sale"
                  /> */}
                  {/* <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                    <svg
                      className="h-20 w-20 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 84 84"
                    >
                      <circle
                        opacity="0.9"
                        cx="42"
                        cy="42"
                        r="42"
                        fill="white"
                      />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
