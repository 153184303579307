import React from 'react';

export default function News() {
  return (
    <div class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Recent news
          </h2>
          {/* <div class="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
            <p class="text-xl leading-7 text-gray-500">
              Learn more about my recent research projects.
            </p>
          </div> */}
        </div>
        <div class="mt-6 grid gap-16 border-t-2 border-gray-100 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
        {/* <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">June 2022</time>
            </p>
            <div class="block">
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Human Factors Jounral Paper Accepted
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                I will be joining&nbsp;
                <a
                  href="http://evolutionengineering.us/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Evolution Engineering
                </a>
                &nbsp;as a human factors engineering intern
                this summer to conduct user research on medical devices.
              </p>
            </div>
          </div> */}
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">May 2022</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Human Factors Engineering Intern
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                I will be joining&nbsp;
                <a
                  href="http://evolutionengineering.us/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Evolution Engineering
                </a>
                &nbsp;as a human factors engineering intern
                this summer to conduct user research on medical devices.
              </p>
            </div>
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">May 2021</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                UX Research Intern
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                I will be joining&nbsp;
                <a
                  href="https://cdkglobal.com/us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  CDK Global
                </a>
                &nbsp;as a user experience resesarch intern
                this summer to help with user research using
                quantitative and qualitative methods.
                {/* My project <b>Apart 2.0</b> for course <i>Computing Technologies and Online Relationships</i> provided an initial understanding of
                  how couples deal with breakup on social media in a digital age. How do current technologies support breakup behaviors on social media?
                  What barriers do couples encounter in post-breakup on social media? */}
              </p>
            </div>
            {/* <div class="mt-3">
                <a
                  href="#"
                  class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
                >
                  Read full story
                </a>
              </div> */}
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">Apr 2021</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                AIES 2021
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                Our paper&nbsp;
                <a
                  href="https://dl.acm.org/doi/abs/10.1145/3461702.3462573"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                Modeling and Guiding the Creation
                of Ethical Human-AI Teams
                </a>
                &nbsp;has been accepted to AIES 2021!
                {/* My project <b>Apart 2.0</b> for course <i>Computing Technologies and Online Relationships</i> provided an initial understanding of
                how couples deal with breakup on social media in a digital age. How do current technologies support breakup behaviors on social media?
                What barriers do couples encounter in post-breakup on social media? */}
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">Dec 2020</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Passed the comprehensive exam!
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                I passed the comprehensive examination! Now
                I am a PhD Candidate!
                {/* My project <b>Apart 2.0</b> for course <i>Computing Technologies and Online Relationships</i> provided an initial understanding of
                how couples deal with breakup on social media in a digital age. How do current technologies support breakup behaviors on social media?
                What barriers do couples encounter in post-breakup on social media? */}
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">Dec 2020</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                CSCW 2021
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                Our paper&nbsp;
                <a
                  href="https://dl.acm.org/doi/abs/10.1145/3449123"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Leveling Up Teamwork in Esports:
                  Understanding Team Cognition in a Dynamic
                  Virtual Environment
                </a>
                &nbsp;has been accepted to CSCW 2021!
                {/* My project <b>Apart 2.0</b> for course <i>Computing Technologies and Online Relationships</i> provided an initial understanding of
                how couples deal with breakup on social media in a digital age. How do current technologies support breakup behaviors on social media?
                What barriers do couples encounter in post-breakup on social media? */}
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">Aug 2020</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                CSCW 2020
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                Our paper &nbsp;
                <a
                  href="https://dl.acm.org/doi/abs/10.1145/3432945"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  “An Ideal Human": Expectations of AI
                  Teammates in Human-AI Teaming
                </a>
                &nbsp; has been accepted to CSCW 2020!
                {/* My project <b>Apart 2.0</b> for course <i>Computing Technologies and Online Relationships</i> provided an initial understanding of
                how couples deal with breakup on social media in a digital age. How do current technologies support breakup behaviors on social media?
                What barriers do couples encounter in post-breakup on social media? */}
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-10">Jul 2020</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                CSCW 2020 Poster
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                Our paper&nbsp;
                <a
                  href="https://dl.acm.org/doi/10.1145/3406865.3418310"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Breakups on Social Media: Social Behaviors
                  and Dilemmas
                </a>
                &nbsp;has been accepted to CSCW 2020
                Posters
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
          {/* CHI 2020 Student Game Competition */}
          <div>
            <p class="text-sm leading-5 text-gray-500">
              <time datetime="2020-03-16">Jan 2020</time>
            </p>
            <div class="block">
              {/* text-gray-900 */}
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                CHI 2020 Student Game Competition: Lost in
                Spaze
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                Our game <em>Lost in Spaze</em> has been
                selected as a finalist (top five in each
                category) in CHI 2020 Student Game
                Competition. Our papers&nbsp;
                <a
                  href="https://dl.acm.org/doi/10.1145/3334480.3381660"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-gray-700 underline"
                >
                  Lost in Spaze: An Audio Maze Game for the
                  Visually Impaired
                </a>
                &nbsp;has been accepted in CHI EA ’20.
                (Acceptance rate: 20.83%)
                {/* Our paper{' '}
                <a
                  href="https://dl.acm.org/doi/10.1145/3334480.3381660"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="font-medium text-gray-700 underline"
                >
                  Lost in Spaze: An Audio Maze Game for the
                  Visually Impaired
                </a>{' '}
                has been accepted in CHI EA'20. (Acceptance
                rate: 20.83%) */}
              </p>
            </div>
            {/* <div class="mt-3">
              <a
                href="#"
                class="text-base leading-6 font-semibold text-green-600 hover:text-green-500 transition ease-in-out duration-150"
              >
                Read full story
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
