import React from 'react';
import Seo from 'components/Seo/Seo';
import Splash from 'components/Splash/Splash';
import Features from 'components/Features/Features';
import News from 'components/News/News';
import Cta from 'components/Cta/Cta';
import Contact from 'components/Contact/Contact';
import Footer from 'components/Footer/Footer';

export default function IndexPage() {
  return (
    <React.Fragment>
      <Seo title="Home" />
      <Splash />
      <Features />
      <News />
      {/* <Cta /> */}
      {/* <Contact /> */}
      <Footer />
    </React.Fragment>
  );
}
