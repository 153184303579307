import React from 'react';

export default function Cta() {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Want to know more? <br />
          Send me a message! <br />
          I’d love to hear from you!
        </h2>
        {/* <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Contact
            </a>
          </div>
          <div className="ml-3 inline-flex">
            <a
              // href="/rui-zhang-curriculum-vitae.pdf"
              href="https://drive.google.com/file/d/1HiXlMLUm2ok6ZmT8HTYJAVGE1-YpORiE/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-700 bg-green-100 hover:text-green-600 hover:bg-green-50 focus:outline-none focus:shadow-outline focus:border-green-300 transition duration-150 ease-in-out"
            >
              Curriculum vitae
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}
// lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2
