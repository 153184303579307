import React from 'react';
import {
  UserGroupSolid,
  ScaleSolid,
  DesktopComputerSolid,
  LightningBoltSolid,
  VolumeUpSolid,
  UserRemoveSolid,
  MapSolid,
} from '@graywolfai/react-heroicons';
import { Link } from 'gatsby';

export default function Features() {
  return (
    // bg-gray-50
    <div className="overflow-hidden">
      <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              UX Projects
            </h3>
          </div>
          <div className="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                <UserGroupSolid className="h-6 w-6" />
                {/* <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg> */}
              </div>
              <div className="mt-5">
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Onboarding System Design and Evaluation
                  {/* New Employee Onboarding Web Page/ Mobile */}
                  {/* App Design */}
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Applied user interviews, persona,
                  prototyping to create a web portal and
                  mobile app to provide usable and efficient
                  onobarding process.
                  <div className="mt-3">
                    <ProjectLink to="/projects/onboarding-app-design-and-evaluation/">
                      More details
                    </ProjectLink>
                  </div>
                </p>
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                <UserRemoveSolid className="h-6 w-6" />
              </div>
              <div className="mt-5">
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  {/* Perception of AI Teammates */}
                  User Experience of Breakups on Social
                  Media
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {/* Reseach that focuses on how people
                  perceive AI, especially in a collaborative
                  environment, and what motivates the
                  generation of their perceptions. */}
                  Explored user experience of disconnecting
                  from ex-partners on social media during
                  post-breakup.
                  <div className="mt-3">
                    <ProjectLink to="/projects/user-experience-of-breakups-on-social-media/">
                      More details
                    </ProjectLink>
                  </div>
                </p>
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                <VolumeUpSolid className="h-6 w-6" />
                {/* <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg> */}
              </div>
              <div className="mt-5">
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  {/* AI Ethics in Human-AI Teaming */}
                  Audio-based Maze Game User Research
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {/* Studies that explore how people perceive (un)ethical behaviors 
                  from AI teammates and how those behaviors impact trust in AI and team performance. */}
                  Designed an audio-based game using
                  usability testing, semi-structured
                  interview and digitial prototyping.
                  <div className="mt-3">
                    <ProjectLink to="/projects/audio-based-maze-game-design-and-evaluation/">
                      More details
                    </ProjectLink>
                  </div>
                </p>
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                <MapSolid className="h-6 w-6" />
              </div>
              <div className="mt-5">
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  {/* Explanations from AI Teammates in Human-AI Teams */}
                  Space Vacation Mobile App User Study
                </h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  {/* Research that examines the impact of AI explaining side effects of their behaviors,
                  especially how it impacts human trust in AI in human-AI teaming. */}
                  A user study of space travel application
                  using user journey map, persona, survey,
                  interview, usability testing, and
                  qualitative and quantitative analysis.
                  <div className="mt-3">
                    <ProjectLink to="/projects/space-travel-app-design-and-evaluation">
                      More details
                    </ProjectLink>
                  </div>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export function ProjectLink({ children, to, href }) {
  if (to)
    return (
      <div>
        <Link
          className="font-medium text-gray-700 underline"
          to={to}
        >
          {children}
        </Link>
        <span className="font-medium text-gray-700 ml-1">
          →
        </span>
      </div>
    );
  if (href) {
    return (
      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="font-medium text-gray-700 underline"
          href={href}
        >
          {children}
        </a>
        <span className="font-medium text-gray-700 ml-1">
          →
        </span>
      </div>
    );
  }

  return (
    <div>
      <Link
        className="font-medium text-gray-700 underline"
        to="/projects"
      >
        {children}
      </Link>
      <span className="font-medium text-gray-700 ml-1">
        →
      </span>
    </div>
  );
}
