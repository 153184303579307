import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import mailtoLink from 'mailto-link';

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      ContactImage: file(
        relativePath: {
          eq: "kevin-bhagat-zNRITe8NPqY-unsplash.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    window.open(
      mailtoLink({
        to: 'rzhang2@g.clemson.edu',
        subject: 'Hello 👋',
        body: message,
      }),
    );
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div id="contact" className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img
            fluid={data.ContactImage.childImageSharp.fluid}
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            alt="Productive desk."
          />
          {/* <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
            alt=""
          /> */}
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              Send me a message
            </h2>
            <p className="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
              I’d love to hear from you! Send me a message
              using the form below, or email me.
            </p>
            <dl className="mt-8 text-base leading-6 text-gray-500">
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="ml-3">
                    rui.uxr@gmail.com
                  </span>
                </dd>
              </div>
            </dl>
            <form
              className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={onSubmit}
            >
              {/* <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="first_name"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Last name
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="last_name"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="name"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    value={name}
                    onChange={(event) =>
                      setName(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    required
                    id="email"
                    type="email"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    value={email}
                    onChange={(event) =>
                      setEmail(event.target.value)
                    }
                  />
                </div>
              </div>
              {/* <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Phone
                  </label>
                  <span className="text-sm leading-5 text-gray-500">
                    Optional
                  </span>
                </div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="phone"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="how_can_we_help"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Message
                  </label>
                  {/* <span className="text-sm leading-5 text-gray-500">
                    Max. 500 characters
                  </span> */}
                </div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <textarea
                    required
                    id="how_can_we_help"
                    rows="4"
                    className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    value={message}
                    onChange={(event) =>
                      setMessage(event.target.value)
                    }
                  ></textarea>
                </div>
              </div>
              {/* <fieldset className="sm:col-span-2">
                <legend className="block text-sm font-medium leading-5 text-gray-700">
                  Expected budget
                </legend>
                <div className="mt-4 grid grid-cols-1 gap-y-4">
                  <div className="flex items-center">
                    <input
                      id="budget_under_25k"
                      name="budget"
                      value="under_25k"
                      type="radio"
                      className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out"
                    />
                    <label
                      htmlFor="budget_under_25k"
                      className="ml-3"
                    >
                      <span className="block text-sm leading-5 text-gray-700">
                        Less than $25K
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="budget_25k-50k"
                      name="budget"
                      value="25k-50k"
                      type="radio"
                      className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out"
                    />
                    <label
                      htmlFor="budget_25k-50k"
                      className="ml-3"
                    >
                      <span className="block text-sm leading-5 text-gray-700">
                        $25K – $50K
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="budget_50k-100k"
                      name="budget"
                      value="50k-100k"
                      type="radio"
                      className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out"
                    />
                    <label
                      htmlFor="budget_50k-100k"
                      className="ml-3"
                    >
                      <span className="block text-sm leading-5 text-gray-700">
                        $50K – $100K
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="budget_over_100k"
                      name="budget"
                      value="over_100k"
                      type="radio"
                      className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out"
                    />
                    <label
                      htmlFor="budget_over_100k"
                      className="ml-3"
                    >
                      <span className="block text-sm leading-5 text-gray-700">
                        $100K+
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset> */}
              {/* <div className="sm:col-span-2">
                <label
                  htmlFor="how_did_you_hear_about_us"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  How did you hear about us?
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="how_did_you_hear_about_us"
                    className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}
              <div className="text-right sm:col-span-2">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                  >
                    Send
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
